import React from 'react'
import {AboutSection, ArticlesSection, ContactSection, InterestsSection, Page, ProjectsSection, Seo} from 'gatsby-theme-portfolio-minimal'
import {HeroSection} from '../gatsby-theme-portfolio-minimal/sections/Hero'

export default function IndexPage() {
  return (
    <>
      <Seo title="Jimmy Marks is a guy making things on the Internet." />
      <Page>
        <HeroSection sectionId="hero" />
        <AboutSection sectionId="about" heading="About me" />
        <InterestsSection sectionId="interests" heading="What I do" />
        <ProjectsSection sectionId="projects" heading="My recent projects" />
        <ContactSection sectionId="github" heading="To sum up..." />
      </Page>
    </>
  )
}
